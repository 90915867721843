import { useState, useEffect } from 'react'

const RedirectPage = () => {
  const [countdown, setCountdown] = useState(10)
  const url = 'https://centraldecontas.datac.com.br'
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1)
    }, 1000)

    // Redireciona após 10 segundos
    const redirectTimer = setTimeout(() => {
      window.location.href = url // Substitua "https://www.example.com" pelo URL externo desejado
    }, 10000)

    return () => {
      clearInterval(timer)
      clearTimeout(redirectTimer)
    }
  }, [])

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>
        O Mio Auth agora é <i>Central de Contas - Data C</i>
      </h2>
      <h3>Redirecionando em {countdown} segundos...</h3>
      <p>
        Você será redirecionado para a próxima página, desenvolvida para
        proporcionar uma experiência. O novo endereço possui um nome mais
        intuitivo e fácil de memorizar.
      </p>
      <p>
        Se preferir, clique <a href={url}>aqui</a> para ir agora.
      </p>
    </div>
  )
}

export default RedirectPage
